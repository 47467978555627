import domReady from './utils/domReady';
import $ from 'jquery';
import 'slick-carousel';
window.jQuery = window.$ = $;  // Make jQuery global

const components = [
	['dealer-search', () => import(/* webpackPrefetch: true */'./components/dealers')],
	['[data-dealer-map]', () => import(/* webpackPrefetch: true */'./components/dealerMap')],
	['script[type="dealer-inventory"]', () => import(/* webpackPrefetch: true */'./components/dealerInventory')],
	['main > resource-cards', () => import(/* webpackPrefetch: true */'./components/resourceCards')],
	['[resource-player]', () => import(/* webpackPrefetch: true */'./components/resourcePlayer')],
	['comp-press', () => import(/* webpackPrefetch: true */'./components/press')],
	['[data-slider]', () => import(/* webpackPrefetch: true */'./components/slider.js')],
	['main > section > form', () => import(/* webpackPrefetch: true */'./components/form')],
	['main > resource-cards > div > section > form', () => import(/* webpackPrefetch: true */'./components/driverTraining')],
	['comp-feature a[href*="#tab["]', () => import(/* webpackPrefetch: true */'./components/featureTabs'), true],
	['main > section > multi-column[slide-show]', () => import(/* webpackPrefetch: true */'./components/slider')],
	['main > section > multi-column > basic-card', () => import(/* webpackPrefetch: true */'./components/multiColumnCard')],
	['footer basic-card', () => import(/* webpackPrefetch: true */'./components/footerCard')],
	['social-container', () => import(/* webpackPrefetch: true */'./components/social')],
	['search-container', () => import(/* webpackPrefetch: true */'./components/search')],
	['[data-scroller]', () => import('./components/scroller.js')],
	['[tabs]', () => import(/* webpackPrefetch: true */'./components/tab')],
	['[accordion]', () => import(/* webpackPrefetch: true */'./components/accordion')],
	['[data-truck-interior-selector]', () => import(/* webpackPrefetch: true */'./components/truck-interior-selector/index.js')],
	['[data-toggle-class]', () => import(/* webpackPrefetch: true */'./components/classToggle.js')],
	['[data-sticky-nav]', () => import(/* webpackPrefetch: true */'./components/stickyNavHighlight.js')],
	['[data-raq-form]', () => import(/* webpackPrefetch: true */'./components/raq.js')],
	['a[elite-support]', () => import('./components/elite-support.js')],
	['[motion-slider]', () => import('./components/motion-slider.js')],
	['[video-player]', () => import('./components/video-player.js')],
	['form[type="newsletter"]', () => import('./components/newsletter-form.js')],
	['[data-react]', () => import('./components/react/index.jsx')],
	['.swiffy-slider', () => import('./components/swiffy-slider.js')],
	['body', () => import(/* webpackPrefetch: true */'./components/alpine/VINtool.js')]
];

domReady.then(() => components.forEach(
	async ([elements, loader, combined]) => {
		const elementList = [...document.querySelectorAll(elements)];

		if (combined && elementList.length > 0) {
			const elementLoader = await loader();
			if (elementLoader.default && typeof elementLoader.default === 'function')
				return await elementLoader.default(elementList);
		}

		return elementList.forEach(
			async (element, index) => {
				const l = await loader();
				if (l.default && typeof l.default === 'function')
					l.default(element, index);
			}
		);
	}
));